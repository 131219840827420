import request from "@/common/utils/request";
import Vue from "vue";

export function getList(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/selectAllCZS",
    method: "get",
    params
  });
}
export function getListtwo(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/bookVisit/list",
    method: "get",
    params
  });
}
export function doEdit(data) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/updateCzs",
    method: "post",
    data
  });
}
export function getvideoList(params) {
  //查询所有视频
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/video/selectAllVideo",
    method: "get",
    params
  });
}
export function updateVideo(data) {
  //修改所有视频
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/video/updateVideo",
    method: "post",
    data
  });
}
export function add(data) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/saveCzs",
    method: "post",
    data
  });
}
export function addvideo(data) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/video/saveVideo",
    method: "post",
    data
  });
}
export function deleteCzs(params) {
  return request({
    url: Vue.prototype.$baseUrl + "/adminapi/deleteCzs",
    method: "get",
    params
  });
}
